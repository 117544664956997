import React, { ReactNode, useEffect } from 'react';
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import { ApolloProvider } from '@apollo/client';
import InfoScreen from '@components/info-screen';
import { BottomAdComponent } from '@components/ui/bottom-ad.component';
import { StyledEngineProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as StyledComponentProvider } from 'styled-components';

import { useApolloClient } from '@/services/apollo-client.service';
import { log } from '@/services/log';
import { useSettingsStore } from '@/store/settings.store';
import { themeMui, themeStyled } from '@/theme';

import '../app.style.css';

import nextI18NextConfig from '../../next-i18next.config';

import { ThemeProvider as MaterialUiProvider } from '@mui/material/styles';

const handleError = (event: ErrorEvent) => {
  log.error('Unhandled error', event.error);
};

const handlePromiseRejection = (event: PromiseRejectionEvent) => {
  log.error('Unhandled promise rejection', event.reason);
};

function MyApp({ Component, pageProps }: AppProps): ReactNode {
  const [rendered, setRendered] = React.useState(false);
  const { debugMode, accessToken } = useSettingsStore();
  const apolloClient = useApolloClient(accessToken);

  useEffect(() => {
    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handlePromiseRejection);

    setRendered(true);

    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handlePromiseRejection);
    };
  }, []);

  if (!apolloClient) {
    return <InfoScreen text={'ApolloClient is not initialized'} />;
  }

  if (!rendered) {
    return <InfoScreen text={'Loading...'} />;
  }

  return (
    <StyledComponentProvider theme={themeStyled}>
      <MaterialUiProvider theme={themeMui}>
        <CssBaseline />
        <StyledEngineProvider injectFirst>
          <ApolloProvider client={apolloClient}>
            <Component {...pageProps} />
            <BottomAdComponent />
          </ApolloProvider>
        </StyledEngineProvider>
      </MaterialUiProvider>
      {debugMode && (
        <Script
          src="https://unpkg.com/vconsole@latest/dist/vconsole.min.js"
          onLoad={() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-new
            new window.VConsole();
          }}
        />
      )}
    </StyledComponentProvider>
  );
}

MyApp.getInitialProps = async (
  context: AppContext,
): Promise<AppInitialProps> => {
  return await App.getInitialProps(context);
};

export default appWithTranslation(MyApp, nextI18NextConfig);
