import React from 'react';
import { useRouter } from 'next/router';
import Icon from '@mui/icons-material/Info';
import styled from 'styled-components';

interface IInfoScreenProperties {
  text: string;
}

export default function InfoScreen(properties: IInfoScreenProperties) {
  const router = useRouter();

  return (
    <PageContainer>
      <StyledIcon />
      <Data>{properties.text}</Data>
      <RefreshButton onClick={router.reload}>Refresh page</RefreshButton>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Data = styled.div`
  color: white;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  font-size: 56px;
  padding: 10px;
  cursor: pointer;
  color: white;
`;

const RefreshButton = styled.button`
  height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 24px;
`;
