import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type Account = {
  __typename?: 'Account';
  id: number;
  externalId: string;
};

type ISettingsState = {
  showBottomAd: boolean;
  setShowBottomAd: (showBottomAd: boolean) => void;

  debugMode: boolean;
  setDebugMode: (debugMode: boolean) => void;

  accessToken: string | undefined;
  setAccessToken: (accessToken: string) => void;

  account: Account | undefined;
  setAccount: (account: Account) => void;

  logout: () => void;
};

export const useSettingsStore = create(
  persist<ISettingsState>(
    (set) => ({
      showBottomAd: true,
      setShowBottomAd: (showBottomAd) => set({ showBottomAd }),
      debugMode: false,
      setDebugMode: (debugMode: boolean) => set({ debugMode }),
      accessToken: undefined,
      setAccessToken: (accessToken) =>
        set(() => ({
          accessToken,
        })),
      account: undefined,
      setAccount: (account) =>
        set(() => ({
          account,
        })),
      logout: () => {
        set(() => ({
          accessToken: undefined,
          account: undefined,
        }));
      },
    }),
    {
      name: 'settings-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
